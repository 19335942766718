import React from "react";

const TopNavSection = () => {
  return (
    <div className="bg-[#111] text-white m-0 mb-3 p-2 -mt-6 w-full hidden sm:flex items-center justify-center mx-auto">
      <p className="font-medium font-Roboto text-xs">
        Buy 2 products and get 10% off! Use Coupon Yuva10
      </p>
    </div>
  );
};

export default TopNavSection;
